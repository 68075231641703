import { icon_fa_gear } from "assets/svgs/fontawesomeSvgs";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editCollectionTypeField, updateEditPageCEField } from "store/actions";
import { getTargetValue } from "utils/util";
import CmsContentButtonStyle from "./cmsContentButtonStyle";

const Button = dynamic(() => import("@mui/material/Button"));

// NOTE: import contentmanager only components dynamic
const CmsMaxLengthIndicator = dynamic(() =>
  import(
    "components/cms/contentManagerControls/cmsMaxLengthIndicator/cmsMaxLengthIndicator"
  )
);
const CmsPopoverButton = dynamic(() =>
  import("components/cms/contentManagerControls/cmsPopover/cmsPopoverButton")
);
const CmsLinkChooser = dynamic(() =>
  import("components/cms/contentManagerControls/cmsLinkChooser/cmsLinkChooser")
);

/**
 * Component for updating/displaying (editmode/editview) values
 * from the CMS page.content array
 * Do not use this for other purposes

 * 
 * @param {Object} props
 * @param {String} props.type // eg. type="submit" if you want to submit a form with cmsButton 
 * @param {Function} props.onClick // onClick to use as Button
 * @param {String} props.url // url has priority and makes cmsButton a Link
 * @param {String} props.text // button text
 * @param {String} props.styleType // (primary | primär | 1) || (secondary | sekundär | 2)
 * @param {string} props.popoverPlacement should the popover appear top, bottom, left, rigth, defaults to top
 * @returns
 * @example 
 * // Link:
 * <CmsContentButton
      styleType={props.settings.buttonStyle}
      text={props.content.btnText}
      url={props.content.btnUrl}
      internalLink={props.content.btnInternalLink}
      cmsPosition={props.position}
      cmsFieldText="btnText"
      cmsFieldUrl="btnUrl"
    />
  
 * // Button:
 * <CmsContentButton
      styleType={props.settings.buttonStyle}
      text={props.content.btnText}
      onClick={() => submitFunction()}
      cmsPosition={props.position}
      cmsFieldText="btnText"
    />
 * 
 * 
 * 
 */
const CmsContentButton = (props) => {
  const { t: tCms } = useTranslation("cms");

  const editView = useSelector((state) => state.cms.editView);
  const dispatch = useDispatch();

  const btnTextInputRef = useRef(null);
  const btnStyle =
    props.styleType === "primary"
      ? "primary "
      : props.styleType === "secondary"
      ? "secondary "
      : props.styleType === "custom-tag"
      ? "custom-tag "
      : "";

  const handleTextChange = (e) => {
    if (props.contentType) {
      dispatch(
        editCollectionTypeField(
          props.contentType,
          props.cmsFieldText,
          e.target.value,
          props.cmsPosition
        )
      );
    } else {
      // default case no props.contentType given -> Contentpage content array
      dispatch(
        updateEditPageCEField(
          props.cmsPosition,
          props.cmsFieldText,
          e.target.value
        )
      );
    }
  };

  return (
    <>
      {editView && !props.disableEditView ? (
        <>
          <div className="gear-symbol-btn-wrapper">
            <div className="mui-button-wrapper">
              <Button
                type="button"
                className={`${
                  props.className ? `${props.className} ` : ""
                }${btnStyle}mui-cms-button`}
                tabIndex={-1}
              >
                <div className="with-max-length-indicator">
                  <input
                    className="cms-button-input"
                    type="text"
                    placeholder={tCms("button-buttonText")}
                    defaultValue={props.text ? props.text : ""}
                    onChange={handleTextChange}
                    maxLength={250}
                    ref={btnTextInputRef}
                    style={{
                      // quick styling for buttons in edit view
                      borderRadius: "6px",
                      border: "1px solid #ccc",
                      backgroundColor: "transparent",
                      color: "inherit",
                    }}
                  />
                  <CmsMaxLengthIndicator
                    maxLength={250}
                    margin={"1px"}
                    fontSize={"10px"}
                    length={btnTextInputRef.current?.value.length}
                  />
                </div>
              </Button>
              {(props.url || !props.onClick) && (
                <CmsPopoverButton
                  placement={props.popoverPlacement}
                  buttonTitle={<>{icon_fa_gear("svg-fill-white", 20)}</>}
                  title={tCms("button-buttonConfig")}
                  className={
                    props.popoverClassName
                      ? props.popoverClassName
                      : "cms-popover-btn"
                  } // default is cms-popover-btn
                  cssPosition="absolute"
                  cssPosTop="5px"
                  cssPosRight="4px"
                  minWidth="350px"
                  minHeight="240px"
                  rootClose={false}
                  cmsPopoverOpenerId={
                    props.cmsPopoverOpenerId
                      ? props.cmsPopoverOpenerId
                      : `${props.cmsPosition}.btn`
                  }
                >
                  <Row>
                    <Col xs={12}>
                      <CmsLinkChooser
                        contentType={props.contentType}
                        cmsFieldUrl={props.cmsFieldUrl}
                        cmsFieldText={props.cmsFieldText}
                        cmsFieldRel={props.cmsFieldRel}
                        cmsFieldTarget={props.cmsFieldTarget}
                        cmsFieldGetParams={props.cmsFieldGetParams}
                        cmsFieldHrefLang={props.cmsFieldHrefLang}
                        cmsPosition={props.cmsPosition}
                        url={props.url}
                        rel={props.rel}
                        target={props.target}
                        getParams={props.getParams}
                        hrefLang={props.hrefLang}
                      />
                    </Col>
                  </Row>
                </CmsPopoverButton>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {props.text && (props.url || props.onClick) ? (
            <>
              {!props.url && props.onClick ? (
                <div
                  className="mui-button-wrapper"
                  style={props.disabled ? { cursor: "not-allowed" } : null}
                >
                  <Button
                    onClick={props.onClick}
                    type={props.type || "button"}
                    className={`${
                      props.className ? `${props.className} ` : ""
                    }${btnStyle}mui-cms-button`}
                    disabled={props.disabled ? true : false}
                  >
                    {props.text}
                  </Button>
                </div>
              ) : (
                <div
                  className="mui-button-wrapper"
                  style={props.disabled ? { cursor: "not-allowed" } : null}
                >
                  <Link
                    href={`${props.url}${
                      props.getParams ? props.getParams : ""
                    }`}
                    passHref
                  >
                    <Button
                      rel={props.rel ? props.rel : null}
                      hrefLang={props.hrefLang ? props.hrefLang : null}
                      target={
                        props.target ? props.target : getTargetValue(props.url)
                      }
                      className={`${
                        props.className ? `${props.className} ` : ""
                      }${btnStyle}mui-cms-button`}
                      disabled={props.disabled ? true : false}
                    >
                      {props.text}
                    </Button>
                  </Link>
                </div>
              )}
            </>
          ) : null}
        </>
      )}
      <CmsContentButtonStyle />
    </>
  );
};

export default CmsContentButton;
