import { globalSettings } from "services/globalSettings/globalSettingsService";
import { getGlobalSettingsColor } from "utils/util";

// buttonfont, buttonfontspcingem, buttontexttransform, buttonletterspacingem are missing in CSS

export const cmsContentButtonStyle = () => {
  const settings = globalSettings.button;
  return (
    <style jsx global>{`
      .mui-cms-button {
        width: 100%;
        max-width: ${settings.buttonmaxwidth}px !important;
        min-width: ${settings.buttonminwidth}px !important;
        text-align: center;

        &.MuiButtonBase-root {
          background-color: ${getGlobalSettingsColor(
            settings.defaultButtonColor
          )};
          border: ${settings.defaultButtonBorder !== ""
            ? settings.defaultButtonBorder
            : "none"};
          border-radius: ${settings.defaultButtonBorderRadius}px;
          font-weight: ${settings.buttonfontweight};
          padding: ${`${settings.buttonpaddingvertical}px ${settings.buttonpaddinghorizontal}px`};
          text-transform: none;
          font-family: inherit !important;
          color: ${getGlobalSettingsColor(settings.defaultButtonFontColor)};
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            background-color: ${getGlobalSettingsColor(
              settings.defaultButtonColorHover
            )};
            color: ${getGlobalSettingsColor(
              settings.defaultButtonFontColorHover
            )};
            border-color: ${getGlobalSettingsColor(
              settings.defaultButtonBorderHoverColor
            )};
          }
        }
        &.primary {
          &.MuiButtonBase-root {
            background-color: ${getGlobalSettingsColor(
              settings.primaryButtonColor
            )};

            border: ${settings.primarybuttonborder !== ""
              ? settings.primarybuttonborder
              : "none"};
            border-radius: ${settings.primarybuttonborderradius}px;

            color: ${getGlobalSettingsColor(settings.primaryButtonFontColor)};

            &:hover {
              background-color: ${getGlobalSettingsColor(
                settings.primaryButtonColorHover
              )};

              color: ${getGlobalSettingsColor(
                settings.primaryButtonFontColorHover
              )};
              border-color: ${getGlobalSettingsColor(
                settings.primaryButtonBorderHoverColor
              )};
            }
          }
        }
        &.secondary {
          &.MuiButtonBase-root {
            background-color: ${getGlobalSettingsColor(
              settings.secondaryButtonColor
            )};
            border: ${settings.secondarybuttonborder !== ""
              ? settings.secondarybuttonborder
              : "none"};
            border-radius: ${settings.secondarybuttonborderradius}px;

            color: ${getGlobalSettingsColor(settings.secondaryButtonFontColor)};

            &:hover {
              background-color: ${getGlobalSettingsColor(
                settings.secondaryButtonColorHover
              )};

              color: ${getGlobalSettingsColor(
                settings.secondaryButtonFontColorHover
              )};
              border-color: ${getGlobalSettingsColor(
                settings.secondaryButtonBorderHoverColor
              )};
            }
          }
        }
        input {
          text-align: center;
          outline: none;
          border: 1px transparent solid;
          width: 100%;
          color: black;
          min-height: 30px;
        }
      }
      .mui-button-wrapper {
        width: fit-content;
        max-width: 100%;
      }
      .gear-symbol-btn-wrapper {
        position: relative;
        width: fit-content;

        .mui-cms-button {
          padding-right: ${settings.buttonpaddinghorizontal < 30
            ? "30px"
            : settings.buttonpaddinghorizontal}px !important;
          padding-left: ${settings.buttonpaddinghorizontal < 30
            ? "30px"
            : settings.buttonpaddinghorizontal}px !important;
        }
      }
    `}</style>
  );
};

export default cmsContentButtonStyle;
